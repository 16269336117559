* {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.print {
  display: none;
}

@media print {
  * {
    font-size: 80%;
  }
  .editable {
    background-color: white !important;
  }
  .print {
    margin: 1rem;
    display: block;
  }
  @page {
    size: A5 landscape;
  }
}
